<script setup lang="ts">
import type { HomeBanners, BannerPresetPackage } from "~/types";

import type { KeenSliderOptions } from "keen-slider";
type Modals = {
	LazyOModalDailyWheel: "LazyOModalDailyWheel";
	LazyOModalPresetPackage: "LazyOModalPresetPackage";
};

const props = defineProps<{
	banners: HomeBanners & BannerPresetPackage[];
}>();

const { data: homeData, refresh: refreshHomeData } = useHomeData({ immediate: false });
const { data: appInitData, refresh: refreshAppInitData } = useAppInitData({ immediate: false });
const isGuest = useIsGuest();
const { open } = useNlcModals();
const { handleOpenGame } = useOpenGame(open);
const { bonusesData, refreshBonusesData } = useBonusesData();
const isDesktop = useMediaQuery("(min-width: 1300px)");

const refreshData = () => {
	setTimeout(() => {
		refreshAppInitData();
		refreshHomeData();
		refreshBonusesData();
	}, 600);
};

const sliderRef = ref();
const isLoading = ref(false);

const sliderOptions: KeenSliderOptions<{ autoplay?: { delay: number } }> = {
	defaultAnimation: {
		duration: 1000
	},
	slides: { perView: 2, spacing: 16 },
	breakpoints: {
		"(max-width: 1300px)": {
			slides: { perView: "auto", spacing: 16 }
		}
	},
	autoplay: { delay: 5000 }
};

const getCoinsValue = (type: string, key: "prizeEntries" | "prizeCoins") => {
	if (type === "invite") {
		const promo = homeData.value?.promotions;
		return promo?.find((p) => p.type === "invite")?.data?.[key] || 0;
	}

	return 0;
};

const getTimeValue = (type: string) => {
	if (type === "dailyWheel") {
		return bonusesData.value?.dailyWheel?.reasonType === "time" ? bonusesData.value?.dailyWheel?.reasonValue : 0;
	}

	return 0;
};

watch(
	() => props.banners?.length,
	() => {
		nextTick(() => {
			sliderRef.value?.slider?.update();
		});
	}
);

const handleClick = (banner: HomeBanners[number]) => {
	if (isGuest.value || banner.buttonLink === "/register/") {
		open("LazyOModalSignup");
		return;
	}

	if (!banner.buttonLink) {
		return;
	}

	if (banner.buttonLink) {
		if (banner.buttonLink.includes("facebook")) {
			window.open(banner.buttonLink, "_blank");
			return;
		}
		if (banner.buttonLink.includes("game=")) {
			const slug = banner.buttonLink.replace("game=", "");
			dispatchGAEvent({
				event: "click_button",
				button_name: "money_play",
				location: slug,
				form_name: "banner"
			});
			handleOpenGame(slug);
			return;
		}

		if (banner.buttonLink.includes("openModal=")) {
			const slug = banner.buttonLink.replace("openModal=", "");
			open(slug as keyof Modals);
			return;
		}

		navigateTo(banner.buttonLink);
	}
};

const handlePresetPackageClick = (banner: BannerPresetPackage) => {
	if (isGuest.value) {
		open("LazyOModalSignup");
		return;
	}

	dispatchGAEvent({
		event: "click_button",
		button_name: "deposit",
		location: "Your Personal Offer",
		quantity: appInitData.value?.depositsCount ?? 0
	});
	dispatchGAEvent({
		event: "click_button",
		button_name: "deposit",
		location: "slider",
		step: "view_payments_info"
	});
	window?.$cash?.$store?.dispatch?.("cash/setOfferType", "promoOffer");
	window?.$cash?.$store?.dispatch?.("cash/setOfferId", banner?.promoOfferPreset?.id || 0);
	window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${banner?.promoOfferPreset?.id || 0}/promoOffer/`);
};

onMounted(() => {
	setTimeout(() => {
		isLoading.value = true;
	}, 1000);
});
</script>

<template>
	<div class="banners">
		<div v-if="!isLoading" class="box-skeletons">
			<AAnimationSkeleton v-for="i in 2" :key="i">
				<ASkeleton class="skeleton" />
			</AAnimationSkeleton>
		</div>
		<LazyASlider ref="sliderRef" :options="sliderOptions">
			<template v-for="(banner, index) in banners" :key="`banner_${index}`">
				<LazyMHomeBanner
					v-if="(banner as BannerPresetPackage) && banner?.type === 'bannerPresetPackage'"
					class="keen-slider__slide"
					:img-desk="banner?.promoOfferPreset?.image2x || ''"
					:img-mob="banner?.promoOfferPreset?.imageMobile2x || ''"
					:bg-desk="banner?.promoOfferPreset?.imageBanner || ''"
					:bg-mob="banner?.promoOfferPreset?.imageBannerMobile || ''"
					:title="banner?.promoOfferPreset?.title || ''"
					:small-title="banner?.smallTitle || ''"
					:sub-title="banner?.subTitle || ''"
					:button-text="banner?.buttonText || ''"
					:entries="banner?.promoOfferPreset?.entries || 0"
					:coins="banner?.promoOfferPreset?.coins || 0"
					:money="banner?.money || 0"
					:badge-label="banner?.promoOfferPreset?.badgeLabel"
					:usual-price="banner?.usualPrice || 0"
					:time="banner?.promoOfferPreset?.availableTill || 0"
					:bestDeal="banner?.promoOfferPreset?.bestDeal"
					:mostPopular="banner?.promoOfferPreset?.mostPopular"
					:type="banner?.type || ''"
					@on-timer-end="refreshData"
					@click="handlePresetPackageClick(banner)"
				/>
				<LazyMHomeBanner
					v-else
					class="keen-slider__slide"
					:img-desk="banner?.image2x || ''"
					:img-mob="banner?.imageMobile2x || ''"
					:bg-desk="banner?.backgroundImage || ''"
					:bg-mob="banner?.backgroundImage2x || ''"
					:title="banner?.title || ''"
					:small-title="banner?.smallTitle || ''"
					:sub-title="banner?.subTitle || ''"
					:button-text="banner?.buttonText || ''"
					:entries="getCoinsValue(banner?.type, 'prizeEntries')"
					:coins="getCoinsValue(banner?.type, 'prizeCoins')"
					:usual-price="banner?.usualPrice || 0"
					:money="banner?.money || 0"
					:time="banner?.availableTill || getTimeValue(banner?.type)"
					:button-link="banner?.buttonLink || ''"
					:type="banner?.type || ''"
					@on-timer-end="refreshData"
					@click="handleClick(banner)"
				/>
			</template>

			<template #dots="{ slider, activeIndex }">
				<div
					v-if="slider && ((isDesktop && banners?.length > 2) || (!isDesktop && banners?.length > 1))"
					class="pagination"
				>
					<div
						v-for="index in isDesktop ? banners.length - 1 : banners.length"
						:key="index"
						:class="['pagination-item', { active: activeIndex === index - 1 }]"
						@click="slider && slider.moveToIdx(index - 1)"
					/>
				</div>
			</template>
		</LazyASlider>
	</div>
</template>
<style scoped lang="scss">
div.banners {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	gap: 12px;
	padding: 26px 32px;
	overflow: hidden;
	position: relative;
	min-height: 464px;

	@media (max-width: 1300px) {
		overflow: hidden;
	}

	@include media-breakpoint-down(lg) {
		padding: 16px;
		min-height: 248px;
	}

	&:deep(.keen-slider) {
		position: relative;
		z-index: 1;

		@media (max-width: 1300px) {
			overflow: visible;
		}
	}
}

.box-skeletons {
	display: flex;
	padding: 16px;
	justify-content: flex-start;
	gap: 16px;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	@include media-breakpoint-up(lg) {
		padding: 26px 32px;

		> div {
			width: 100%;
		}
	}
}

.skeleton {
	width: 100%;
	height: 380px;

	@media (max-width: 1300px) {
		width: 602px;
	}

	@include media-breakpoint-down(lg) {
		width: 330px;
		height: 184px;
	}
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.pagination-item {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 20px;
	cursor: pointer;
	padding: 8px 4px;
	transition: 0.3s;

	&:before {
		content: "";
		display: flex;
		width: 100%;
		height: 4px;
		border-radius: 9px;
		opacity: 0.5;
		background: var(--secondary-50);
	}

	@include media-breakpoint-up(lg) {
		&:hover:before {
			opacity: 1;
		}
	}

	&.active:before {
		opacity: 1;
	}

	&.active {
		width: 40px;
	}

	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			&:last-child {
				display: none;
			}
		}
	}
}
</style>
